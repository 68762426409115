import { COUNTRIES, ICountry } from "../constants/data"

type UseCountry = {
  getCountryByName: (name: string) => ICountry | undefined
  getCountryFlag: (name: string) => string | undefined
  getCountryByCode: (code: string) => ICountry | undefined
  countryCodeToName: (code?: string | null) => string | undefined
}

export const useCountry = (): UseCountry => {
  function getCountryByName(name: string) {
    return COUNTRIES.find((countries) => countries.en === name)
  }

  function getCountryByCode(code: string) {
    return COUNTRIES.find((countries) => countries.code === code)
  }

  function getCountryFlag(name: string) {
    if (!name) return
    return getCountryByName(name)?.flag
  }

  function countryCodeToName(code?: string | null) {
    if (!code) return
    return getCountryByCode(code)?.en
  }

  return {
    getCountryByName,
    getCountryFlag,
    getCountryByCode,
    countryCodeToName,
  }
}
